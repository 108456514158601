<template>
  <section class="page page--container">
    <PageHeader style="background-image: url('/images/about-us-banner.png')">
      <h1>
        What we're about
      </h1>
      <p>Thinking of having a family soon? Let us help you.</p>
    </PageHeader>

    <PageContent>
      <h2>Our Story</h2>
      <p>
        The birth of a child is one of the most importent milstones of a parent
        (s) life, Whilst it is one of the most exciting times, it is also
        notably stressful considering the entire journey of pre to post
        pregnancy, including delivery.

        <br /><br />We are a one stop marketplace for our customers to address
        all product and service-related needs during pre, post or during
        pregnancy.
      </p>

      <h2>Below are dummy text;</h2>

      <p>
        Woodlands payment Osgiliath tightening. Barad-dur follow belly comforts
        tender tough bell? Many that live deserve death. Some that die deserve
        life. Outwitted teatime grasp defeated before stones reflection corset
        seen animals Saruman's call?
      </p>
      <h2>Tad survive ensnare joy mistake courtesy Bagshot Row.</h2>
      <p>
        Ligulas step drops both? You shall not pass! Tender respectable success
        Valar impressive unfriendly bloom scraped? Branch hey-diddle-diddle pony
        trouble'll sleeping during jump Narsil.
      </p>
      <h3>North valor overflowing sort Iáve mister kingly money?</h3>
      <p>
        Curse you and all the halflings! Deserted anytime Lake-town burned caves
        balls. <a href="#">Smoked lthilien forbids</a> Thrain?
      </p>
      <ul>
        <li>Adamant.</li>
        <li>Southfarthing!</li>
        <li>Witch-king.</li>
        <li>Precious.</li>
        <li>Gaffer's!</li>
      </ul>
      <ul>
        <li>Excuse tightening yet survives two cover Undómiel city ablaze.</li>
        <li>
          Keepsakes deeper clouds Buckland position 21 lied bicker fountains
          ashamed.
        </li>
        <li>Women rippling cold steps rules Thengel finer.</li>
        <li>
          Portents close Havens endured irons hundreds handle refused sister?
        </li>
        <li>Harbor Grubbs fellas riddles afar!</li>
      </ul>
      <h3>
        Narsil enjoying shattered bigger leaderless retrieve dreamed dwarf.
      </h3>
      <p>
        Ravens wonder wanted runs me crawl gaining lots faster! Khazad-dum
        surprise baby season ranks. I bid you all a very fond farewell.
      </p>
      <ol>
        <li>Narsil.</li>
        <li>Elros.</li>
        <li>Arwen Evenstar.</li>
        <li>Maggot's?</li>
        <li>Bagginses?</li>
      </ol>
      <ol>
        <li>Concerning Hobbits l golf air fifth bell prolonging camp.</li>
        <li>Grond humble rods nearest mangler.</li>
        <li>Enormity Lórien merry gravy stayed move.</li>
        <li>
          Diversion almost notion furs between fierce laboring Nazgûl
          ceaselessly parent.
        </li>
        <li>Agree ruling um wasteland Bagshot Row expect sleep.</li>
      </ol>
      <h3>Ere answering track forests shards roof!</h3>
      <p>
        Delay freezes Gollum. Let the Ring-bearer decide. Bagshot Row chokes
        pole pauses immediately orders taught éored musing three-day? Disease
        rune repel source fire Goblinses already?
      </p>
      <h4>Afraid smithy Fellowship debt carven hooks.</h4>
      <p>
        What about second breakfast? Nags runt near Lindir lock discover level?
        Andûril breathe waited flatten union.
      </p>

      <br />
      <br />

      <div class="btn-group">
        <a href="" class="btn btn--lg btn--primary" style="margin-right: 20px"
          >Customer Sign Up</a
        >
        <a href="" class="btn btn--lg btn--primary btn--outline"
          >Business Sign Up</a
        >
      </div>
    </PageContent>
  </section>
</template>

<script>
import { PageHeader } from '@/components/molecules/PageHeader'
import { PageContent } from '@/components/molecules/PageContent'

export default {
  name: 'ContentPage',
  data() {},
  components: {
    PageHeader,
    PageContent
  }
}
</script>

<style scoped lang="scss"></style>
