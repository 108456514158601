<template>
  <div class="thumbnail-cards-block">
    <div class="container">
      <h3 class="section-heading">
        Getting Organised
      </h3>

      <p class="section-description">
        Compare Your Options to Find the Right Pregnancy Cover. To help you make
        the most informed decision for your needs, we’ve dispayed the different
        types of insurance companiese on how pregnancy is covered in private
        health insurance.
      </p>

      <div class="thumbnail-cards-block__list">
        <div class="row-1">
          <div class="col-1">
            <a class="thumbnail-card" href="/">
              <div class="thumb">
                <img
                  class="thumbnail-card__thumbnail"
                  src="/images/thumbnail-image.jpeg"
                />
              </div>
              <div class="thumbnail-card__meta">
                <div class="thumbnail-card__logo">
                  <img src="/images/logo/2.png" />
                </div>
                <span class="thumbnail-card__title">
                  Aetna <br />Pregnancy Cover
                </span>
              </div>
            </a>
          </div>

          <div class="col-2">
            <a class="thumbnail-card" href="/">
              <div class="thumb">
                <img
                  class="thumbnail-card__thumbnail"
                  src="/images/thumbnail-3.jpeg"
                />
              </div>
              <div class="thumbnail-card__meta">
                <div class="thumbnail-card__logo">
                  <img src="/images/logo/7.svg" />
                </div>
                <span class="thumbnail-card__title">
                  Aetna <br />Pregnancy Cover
                </span>
              </div>
            </a>
          </div>
        </div>

        <div class="row-2">
          <div class="col-1">
            <div class="row-1">
              <div class="col-1">
                <a class="thumbnail-card" href="/">
                  <div class="thumb">
                    <img
                      class="thumbnail-card__thumbnail"
                      src="/images/thumbnail-4.jpeg"
                    />
                  </div>
                  <div class="thumbnail-card__meta">
                    <div class="thumbnail-card__logo">
                      <img src="/images/logo/5.png" />
                    </div>
                    <span class="thumbnail-card__title">
                      Aetna <br />Pregnancy Cover
                    </span>
                  </div>
                </a>
              </div>

              <div class="col-2">
                <a class="thumbnail-card" href="/">
                  <div class="thumb">
                    <img
                      class="thumbnail-card__thumbnail"
                      src="/images/thumbnail-5.jpeg"
                    />
                  </div>
                  <div class="thumbnail-card__meta">
                    <div class="thumbnail-card__logo">
                      <img src="/images/logo/7.svg" />
                    </div>
                    <span class="thumbnail-card__title">
                      Aetna <br />Pregnancy Cover
                    </span>
                  </div>
                </a>
              </div>
            </div>

            <div class="row-2">
              <div class="col-1">
                <a class="thumbnail-card" href="/">
                  <div class="thumb">
                    <img
                      class="thumbnail-card__thumbnail"
                      src="/images/thumbnail-6.jpeg"
                    />
                  </div>
                  <div class="thumbnail-card__meta">
                    <div class="thumbnail-card__logo">
                      <img src="/images/logo/9.svg" />
                    </div>
                    <span class="thumbnail-card__title">
                      Aetna <br />Pregnancy Cover
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div class="row-1">
              <div class="col-1">
                <a class="thumbnail-card" href="/">
                  <div class="thumb">
                    <img
                      class="thumbnail-card__thumbnail"
                      src="/images/thumbnail-7.jpeg"
                    />
                  </div>
                  <div class="thumbnail-card__meta">
                    <div class="thumbnail-card__logo">
                      <img src="/images/logo/5.png" />
                    </div>
                    <span class="thumbnail-card__title">
                      Aetna <br />Pregnancy Cover
                    </span>
                  </div>
                </a>
              </div>
            </div>

            <div class="row-2">
              <div class="col-1">
                <a class="thumbnail-card" href="/">
                  <div class="thumb">
                    <img
                      class="thumbnail-card__thumbnail"
                      src="/images/thumbnail-8.jpeg"
                    />
                  </div>
                  <div class="thumbnail-card__meta">
                    <div class="thumbnail-card__logo">
                      <img src="/images/logo/2.png" />
                    </div>
                    <span class="thumbnail-card__title">
                      Aetna <br />Pregnancy Cover
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThumbnailCardsBlock',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './ThumbnailCardsBlock';
</style>
