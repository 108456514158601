<template>
  <section class="page page--container">
    <FindBusinessForm />
    <BusinessList />
  </section>
</template>

<script>
import { FindBusinessForm } from '@/components/molecules/FindBusinessForm'
import { BusinessList } from '@/components/molecules/BusinessList'

export default {
  name: 'DirectoryPage',
  data() {},
  components: {
    FindBusinessForm,
    BusinessList
  }
}
</script>

<style scoped lang="scss"></style>
