<template>
  <div class="authenticated-layout">
    <Header is-authenticated="true" />
    <div class="layout-container">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import { Header } from '@/components/molecules/Header'
import { Footer } from '@/components/molecules/Footer'

export default {
  name: 'AuthenticatedLayout',
  components: { Header, Footer },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped></style>
