<template>
  <header class="header">
    <div class="container">
      <div class="header__logo">
        <a href="/home"><img src="/images/logo.png"/></a>
      </div>

      <SearchInput />

      <Avatar
        image="/images/profile-2.png"
        label="Nikola Tesla"
        v-show="isAuthenticated"
      />

      <button
        v-show="!isAuthenticated"
        class="menu-toggle"
        @click="showNavigation = !showNavigation"
      >
        <i class="ri-menu-line" />
      </button>

      <Navigation
        v-show="!isAuthenticated"
        v-bind:class="{ active: showNavigation }"
      />
    </div>
  </header>
</template>

<script>
import { SearchInput } from '@/components/molecules/SearchInput'
import { Navigation } from '@/components/molecules/Navigation'
import { Avatar } from '@/components/molecules/Avatar'

export default {
  name: 'Header',
  data() {
    return {
      showNavigation: false
    }
  },
  props: ['isAuthenticated'],
  components: { SearchInput, Navigation, Avatar }
}
</script>

<style scoped lang="scss">
@import 'Header';
</style>
