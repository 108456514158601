<template>
  <div class="slider-block-one">
    <div class="container">
      <h3 class="section-heading">
        Planning Pregnancy
      </h3>

      <h4 class="section-sub-heading">
        Obstrectian &amp; Gynecologist
      </h4>

      <p class="section-description">
        Start planning your pregnancy journey with the top<br />medical
        professional in Melbourne.
      </p>

      <div class="slider-block-one__slider">
        <template>
          <VueSlickCarousel @init="onInitCarousel" v-bind="settings">
            <div class="profile-card">
              <img src="/images/profile-1.png" />
              <h4>Dr. Charlotte Tribble</h4>
              <p>Gynecologist</p>
            </div>

            <div class="profile-card">
              <img src="/images/profile-2.png" />
              <h4>Dr. Nathan E. Sparks</h4>
              <p>Gynecologist</p>
            </div>

            <div class="profile-card">
              <img src="/images/profile-3.png" />
              <h4>Dr. Tony R. Copeland</h4>
              <p>Gynecologist</p>
            </div>

            <div class="profile-card">
              <img src="/images/profile-4.png" />
              <h4>Dr. Casey L. Lizotte</h4>
              <p>Gynecologist</p>
            </div>

            <div class="profile-card">
              <img src="/images/profile-1.png" />
              <h4>Dr. Charlotte Tribble</h4>
              <p>Gynecologist</p>
            </div>

            <div class="profile-card">
              <img src="/images/profile-2.png" />
              <h4>Dr. Nathan E. Sparks</h4>
              <p>Gynecologist</p>
            </div>

            <div class="profile-card">
              <img src="/images/profile-3.png" />
              <h4>Dr. Tony R. Copeland</h4>
              <p>Gynecologist</p>
            </div>

            <div class="profile-card">
              <img src="/images/profile-4.png" />
              <h4>Dr. Casey L. Lizotte</h4>
              <p>Gynecologist</p>
            </div>

            <template #prevArrow="arrowOption">
              <div class="arrow">
                <img src="/images/arrow-left.png" />
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>

            <template #nextArrow="arrowOption">
              <div class="arrow">
                <img src="/images/arrow-right.png" />
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>

            <template #customPaging="page">
              <div class="slide-dot" />
            </template>
          </VueSlickCarousel>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "SliderBlockOne",
  data() {
    return {
      settings: {
        autoplay: true,
        arrows: true,
        dots: true,
        slidesToShow: 4,
        draggable: false,
        responsive: [
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              dots: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true
            }
          }
        ]
      }
    };
  },
  components: { VueSlickCarousel }
};
</script>

<style lang="scss">
@import "./SliderBlockOne";
</style>
