<template>
  <div class="favorites-list">
    <!-- Favorite Card -->
    <div class="favorite-card">
      <span class="favorite-card__image">
        <img src="/images/logo/11.svg" />
      </span>

      <h4 class="favorite-card__title">The Royal Hospital</h4>
      <p class="favorite-card__description">Women Hospital</p>

      <button class="favorite-card__remove btn--remove">
        <i class="ri-close-fill" />
      </button>
    </div>
    <!-- ./Favorite Card -->

    <!-- Favorite Card -->
    <div class="favorite-card">
      <span class="favorite-card__image">
        <img src="/images/logo/2.png" />
      </span>

      <h4 class="favorite-card__title">The Royal Hospital</h4>
      <p class="favorite-card__description">Women Hospital</p>

      <button class="favorite-card__remove btn--remove">
        <i class="ri-close-fill" />
      </button>
    </div>
    <!-- ./Favorite Card -->

    <!-- Favorite Card -->
    <div class="favorite-card">
      <span class="favorite-card__image">
        <img src="/images/logo/3.png" />
      </span>

      <h4 class="favorite-card__title">The Royal Hospital</h4>
      <p class="favorite-card__description">Women Hospital</p>

      <button class="favorite-card__remove btn--remove">
        <i class="ri-close-fill" />
      </button>
    </div>
    <!-- ./Favorite Card -->

    <!-- Favorite Card -->
    <div class="favorite-card">
      <span class="favorite-card__image">
        <img src="/images/logo/4.png" />
      </span>

      <h4 class="favorite-card__title">The Royal Hospital</h4>
      <p class="favorite-card__description">Women Hospital</p>

      <button class="favorite-card__remove btn--remove">
        <i class="ri-close-fill" />
      </button>
    </div>
    <!-- ./Favorite Card -->

    <!-- Favorite Card -->
    <div class="favorite-card">
      <span class="favorite-card__image">
        <img src="/images/logo/5.png" />
      </span>

      <h4 class="favorite-card__title">The Royal Hospital</h4>
      <p class="favorite-card__description">Women Hospital</p>

      <button class="favorite-card__remove btn--remove">
        <i class="ri-close-fill" />
      </button>
    </div>
    <!-- ./Favorite Card -->

    <!-- Favorite Card -->
    <div class="favorite-card">
      <span class="favorite-card__image">
        <img src="/images/logo/7.svg" />
      </span>

      <h4 class="favorite-card__title">The Royal Hospital</h4>
      <p class="favorite-card__description">Women Hospital</p>

      <button class="favorite-card__remove btn--remove">
        <i class="ri-close-fill" />
      </button>
    </div>
    <!-- ./Favorite Card -->

    <!-- Favorite Card -->
    <div class="favorite-card">
      <span class="favorite-card__image">
        <img src="/images/logo/9.svg" />
      </span>

      <h4 class="favorite-card__title">The Royal Hospital</h4>
      <p class="favorite-card__description">Women Hospital</p>

      <button class="favorite-card__remove btn--remove">
        <i class="ri-close-fill" />
      </button>
    </div>
    <!-- ./Favorite Card -->

    <!-- Favorite Card -->
    <div class="favorite-card">
      <span class="favorite-card__image">
        <img src="/images/logo/10.svg" />
      </span>

      <h4 class="favorite-card__title">The Royal Hospital</h4>
      <p class="favorite-card__description">Women Hospital</p>

      <button class="favorite-card__remove btn--remove">
        <i class="ri-close-fill" />
      </button>
    </div>
    <!-- ./Favorite Card -->
  </div>
</template>

<script>
export default {
  name: 'FavoritesList',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './FavoritesList';
</style>
