<template>
  <div class="footer">
    <div class="container">
      <div class="footer__col col-1">
        <div class="footer__widget">
          <h6 class="footer__widget__heading">The Baby Atlas</h6>
          <p>
            Thinking of starting a family soon? We think starting a family is
            one of the biggest decisions you’ll make in life. That’s why our
            team have put together the following guide to help you find
            everything about pregnany to help take out the confusion and help
            you make an infromed decision for your family.
          </p>

          <ul class="social">
            <li>
              <a href=""><i class="ri-facebook-circle-fill"/></a>
            </li>
            <li>
              <a href=""><i class="ri-twitter-fill"/></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer__col col-2">
        <div class="footer__widget">
          <h6 class="footer__widget__heading">About Us</h6>
          <ul>
            <li><a href="/">Our Story</a></li>
            <li><a href="/">Privacy Policy</a></li>
            <li><a href="/">Terms and Conditions</a></li>
            <li><a href="/">Contact Us</a></li>
          </ul>
        </div>

        <div class="footer__widget" style="width: 270px;">
          <h6 class="footer__widget__heading">For Businesses</h6>
          <ul>
            <li><a href="/">Register and Advertise</a></li>
          </ul>
        </div>

        <div class="footer__widget">
          <h6 class="footer__widget__heading">My Profile</h6>
          <ul>
            <li><a href="/">Join Now</a></li>
            <li><a href="/">Sign In</a></li>
            <li><a href="/">Find Business</a></li>
          </ul>
        </div>

        <div class="footer__widget">
          <h6 class="footer__widget__heading">Support</h6>
          <ul>
            <li><a href="/">Contact Us</a></li>
          </ul>
        </div>
      </div>

      <div class="footer__col col-3">
        <a href="/" class="btn btn--neutral">Explore Now</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './Footer';
</style>
