<template>
  <div class="business-signup-block">
    <div class="authentication-layout authentication-layout--no-bg">
      <!-- Left -->
      <div class="authentication-layout__left">
        <h2 class="authentication-layout__heading">
          Grow Your Business and Enjoy <br />Free Access to Exclusive
          <br />Features
        </h2>

        <ul class="authentication-layout__list">
          <li>
            We are 'a one stop shop’ for our customers and present you with a
            unique opportunity to reach out to customers thourgh our virtual
            market place for all pregnancy releted needs.
          </li>
          <li>
            <span>Your benefits:</span>
            <ul>
              <li>Cost effective advertising</li>
              <li>Far reach to a unique customer base</li>
              <li>Easy Paymen for Your Clients</li>
              <li>Exclusive Features</li>
              <li>Monthly Promotions</li>
            </ul>
          </li>
          <li>
            Instansly build an online presence: With over 300,000 births a year
            on average in Australia by advertising us you can make your presence
            felt.
          </li>
          <li>
            Social Media Presence: We promote your business through our social
            media platforms
          </li>
          <li>No lock-in contracts</li>
          <li>
            Track your journey with us: <br />We provide your progress reporting
            as part of our values addedservice to our partners.
          </li>
        </ul>
      </div>
      <!-- ./Left -->

      <!-- Right -->
      <div class="authentication-layout__right">
        <h2 class="authentication-layout__heading">
          Create Your Business Profile
        </h2>

        <!-- Form -->
        <div class="form business-registration">
          <!-- Row -->
          <div class="row">
            <div class="col">
              <label>Category</label>
              <div class="input-group">
                <select class="form-control--style-two" name="" id="">
                  <option value="">All Categories</option>
                  <option value="">Category one</option>
                  <option value="">Category two</option>
                  <option value="">Category threee</option>
                </select>
                <i class="ri-arrow-down-s-line" />
              </div>
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row">
            <div class="col">
              <label>Business Name</label>
              <input
                type="text"
                placeholder="Enter Business Name"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row">
            <div class="col">
              <label>ACN / ABN</label>
              <input
                type="text"
                placeholder="Enter ACN or ABN"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row">
            <div class="col">
              <label>Email</label>
              <input
                type="text"
                placeholder="Enter Email Address"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row">
            <div class="col">
              <label>Contact No</label>
              <input
                type="text"
                placeholder="Enter Your Contact No#"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row">
            <div class="col">
              <label>Business Address</label>
              <input
                type="text"
                placeholder="Enter Business Address"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row row--left">
            <button class="btn btn--primary btn--lg">Start Free Trial</button>
            <p>
              By clicking “Start Free Trial” you agree to our <br />Terms &amp;
              Conditions
            </p>
          </div>
          <!-- ./Row -->
        </div>
        <!-- ./Form -->
      </div>
      <!-- ./Right -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessSignUpBlock',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './BusinessSignUpBlock';
</style>
