<template>
  <div class="blog-list">
    <div class="container">
      <h3 class="section-heading">
        Pregnancy Care
      </h3>

      <div class="blog-list__items">
        <!-- Blog Card -->
        <div class="blog-card">
          <div class="blog-card__thumbnail">
            <img src="/images/blog-1.png" />
          </div>
          <div class="blog-card__meta">
            <h3 class="blog-card__title">Find Pediatricians</h3>
            <p class="blog-card__excerpt">
              Pediatricians are medical specialists who diagnose, treat and
              provide medical care for babies, children and teenagers.
            </p>
            <a href="/" class="btn btn--primary">Find and Book</a>
          </div>
        </div>
        <!-- // Blog Card -->

        <!-- Blog Card -->
        <div class="blog-card">
          <div class="blog-card__thumbnail">
            <img src="/images/blog-2.png" />
          </div>
          <div class="blog-card__meta">
            <h3 class="blog-card__title">Find Ultrasound Scan Facilities</h3>
            <p class="blog-card__excerpt">
              Ultrasound scan use sound waves to create a picture of your baby
              in your womb. The picture will be displayed on a screen that you
              wull be able to see.
            </p>
            <a href="/" class="btn btn--primary">Find and Book</a>
          </div>
        </div>
        <!-- // Blog Card -->

        <!-- Blog Card -->
        <div class="blog-card">
          <div class="blog-card__thumbnail">
            <img src="/images/blog-3.png" />
          </div>
          <div class="blog-card__meta">
            <h3 class="blog-card__title">Find Massage and Wellness Spa</h3>
            <p class="blog-card__excerpt">
              Experience a relaxation, remedial or specialty massahe treatment
              at your local spa.
            </p>
            <a href="/" class="btn btn--primary">Find and Book</a>
          </div>
        </div>
        <!-- // Blog Card -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogList',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './BlogList';
</style>
