<template>
  <div class="signup-block">
    <div class="authentication-layout">
      <!-- Left -->
      <div class="authentication-layout__left">
        <h2 class="authentication-layout__heading">Join Us</h2>

        <!-- Form -->
        <div class="form">
          <!-- Row -->
          <div class="row">
            <div class="col">
              <input
                type="text"
                placeholder="Username"
                class="form-control--style-two"
              />
            </div>

            <div class="col">
              <input
                type="text"
                placeholder="Email"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row">
            <div class="col">
              <input
                type="password"
                placeholder="Password"
                class="form-control--style-two"
              />
            </div>

            <div class="col">
              <input
                type="password"
                placeholder="Confirm Password"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row" style="padding-top: 25px;">
            <div class="col">
              <label class="custom-checkbox">
                <input type="checkbox" checked />
                <i class="custom-checkbox__icon ri-check-line" />
                <span>I agree with terms of use and privacy policy</span>
              </label>
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row" style="padding-top: 20px;">
            <div class="col">
              <button class="btn btn--primary">Sign Up</button>
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row row--footer">
            <div class="col">
              <button class="btn btn--primary btn--outline">
                Join us as a business
              </button>
            </div>
          </div>
          <!-- ./Row -->
        </div>
        <!-- ./Form -->
      </div>
      <!-- ./Left -->

      <!-- Right -->
      <div
        class="authentication-layout__right"
        style="background-image: url('/images/auth-bg.png')"
      >
        <h2 class="authentication-layout__heading">
          Become a member and enjoy <br />these free perks with a more
          <br />personalized journey.
        </h2>

        <ul class="authentication-layout__list" style="padding-top: 20px;">
          <li>
            Exclusive product deals and offers not available anywhere else
          </li>
          <li>Opportunity to browse and engage multiple service providers</li>
        </ul>
      </div>
      <!-- ./Right -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignUpBlock',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './SignUpBlock';
</style>
