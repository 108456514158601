<template>
  <div class="contact-form">
    <!-- Row -->
    <div class="row">
      <div class="col">
        <input
          type="text"
          class="form-control--style-two"
          placeholder="First Name"
        />
      </div>
      <div class="col">
        <input
          type="text"
          class="form-control--style-two"
          placeholder="Last Name"
        />
      </div>
    </div>
    <!-- Row -->

    <!-- Row -->
    <div class="row">
      <div class="col">
        <input
          type="text"
          class="form-control--style-two"
          placeholder="Email"
        />
      </div>
      <div class="col">
        <input
          type="text"
          class="form-control--style-two"
          placeholder="Subject"
        />
      </div>
    </div>

    <!-- Row -->
    <div class="row">
      <div class="col">
        <textarea
          class="form-control--style-two"
          placeholder="Write your message here..."
        ></textarea>
      </div>
    </div>
    <!-- Row -->

    <!-- Row -->
    <div class="row">
      <div class="col">
        <button class="btn btn--primary">Send</button>
      </div>
    </div>
    <!-- Row -->
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './ContactForm';
</style>
