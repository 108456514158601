<template>
  <div class="sponse">
    <div class="container">
      [Advertising Space]
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sponse',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './Sponse';
</style>
