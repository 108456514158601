<template>
  <section class="page page--container">
    <Hero />
    <SliderBlockOne />
    <SliderBlockTwo />
    <InfoCardsBlock />
    <ThumbnailCardsBlock />
    <SliderBlockThree />
    <BlogList />
    <Sponse />
  </section>
</template>

<script>
import { Hero } from '@/components/molecules/Hero'
import { SliderBlockOne } from '@/components/molecules/SliderBlockOne'
import { SliderBlockTwo } from '@/components/molecules/SliderBlockTwo'
import { InfoCardsBlock } from '@/components/molecules/InfoCardsBlock'
import { ThumbnailCardsBlock } from '@/components/molecules/ThumbnailCardsBlock'
import { SliderBlockThree } from '@/components/molecules/SliderBlockThree'
import { BlogList } from '@/components/molecules/BlogList'
import { Sponse } from '@/components/molecules/Sponse'

export default {
  name: 'HomePage',
  data() {},
  components: {
    Hero,
    SliderBlockOne,
    SliderBlockTwo,
    InfoCardsBlock,
    ThumbnailCardsBlock,
    BlogList,
    SliderBlockThree,
    Sponse
  }
}
</script>

<style scoped lang="scss"></style>
