<template>
  <div class="slider-block-two">
    <div class="container">
      <h4 class="section-sub-heading">
        Home Births
      </h4>

      <p class="section-description">
        A home birth is a birth that takes place in a residance rather than in a
        hospital or a birthing centre. They may be attendeded by a mindwife, or
        lay attendant with experiance in managing home birth.
      </p>

      <div class="slider-block-two__slider">
        <template>
          <VueSlickCarousel @init="onInitCarousel" v-bind="settings">
            <div class="profile-card profile-card--style-two">
              <img src="/images/profile-1.png" />
              <h4>Dr. Charlotte Tribble</h4>
              <p>
                MAMA is a collective of private midwives and allied health
                practitioners who are all focused on pregnancy, birth and
                postnatal care.
              </p>
              <a href="" class="btn btn--primary">Learn More</a>
            </div>

            <div class="profile-card profile-card--style-two">
              <img src="/images/profile-2.png" />
              <h4>Dr. Nathan E. Sparks</h4>
              <p>
                MAMA is a collective of private midwives and allied health
                practitioners who are all focused on pregnancy, birth and
                postnatal care.
              </p>
              <a href="" class="btn btn--primary">Learn More</a>
            </div>

            <div class="profile-card profile-card--style-two">
              <img src="/images/profile-3.png" />
              <h4>Dr. Tony R. Copeland</h4>
              <p>
                MAMA is a collective of private midwives and allied health
                practitioners who are all focused on pregnancy, birth and
                postnatal care.
              </p>
              <a href="" class="btn btn--primary">Learn More</a>
            </div>

            <div class="profile-card profile-card--style-two">
              <img src="/images/profile-4.png" />
              <h4>Dr. Casey L. Lizotte</h4>
              <p>
                MAMA is a collective of private midwives and allied health
                practitioners who are all focused on pregnancy, birth and
                postnatal care.
              </p>
              <a href="" class="btn btn--primary">Learn More</a>
            </div>

            <div class="profile-card profile-card--style-two">
              <img src="/images/profile-1.png" />
              <h4>Dr. Charlotte Tribble</h4>
              <p>
                MAMA is a collective of private midwives and allied health
                practitioners who are all focused on pregnancy, birth and
                postnatal care.
              </p>
              <a href="" class="btn btn--primary">Learn More</a>
            </div>

            <div class="profile-card profile-card--style-two">
              <img src="/images/profile-2.png" />
              <h4>Dr. Nathan E. Sparks</h4>
              <p>
                MAMA is a collective of private midwives and allied health
                practitioners who are all focused on pregnancy, birth and
                postnatal care.
              </p>
              <a href="" class="btn btn--primary">Learn More</a>
            </div>

            <div class="profile-card profile-card--style-two">
              <img src="/images/profile-3.png" />
              <h4>Dr. Tony R. Copeland</h4>
              <p>
                MAMA is a collective of private midwives and allied health
                practitioners who are all focused on pregnancy, birth and
                postnatal care.
              </p>
              <a href="" class="btn btn--primary">Learn More</a>
            </div>

            <div class="profile-card profile-card--style-two">
              <img src="/images/profile-4.png" />
              <h4>Dr. Casey L. Lizotte</h4>
              <p>
                MAMA is a collective of private midwives and allied health
                practitioners who are all focused on pregnancy, birth and
                postnatal care.
              </p>
              <a href="" class="btn btn--primary">Learn More</a>
            </div>

            <template #prevArrow="arrowOption">
              <div class="arrow">
                <img src="/images/arrow-left.png" />
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>

            <template #nextArrow="arrowOption">
              <div class="arrow">
                <img src="/images/arrow-right.png" />
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>

            <template #customPaging="page">
              <div class="slide-dot" />
            </template>
          </VueSlickCarousel>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'SliderBlockTwo',
  data() {
    return {
      settings: {
        autoplay: true,
        arrows: false,
        dots: true,
        slidesToShow: 3,
        draggable: false,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1170,
            settings: {
              slidesToShow: 1
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              centerMode: false
            }
          }
        ]
      }
    }
  },
  components: { VueSlickCarousel }
}
</script>

<style lang="scss">
@import './SliderBlockTwo';
</style>
