import Vue from 'vue'
import App from './App.vue'
import vSelect from 'vue-select'
import router from './router'

import DefaultLayout from './components/layouts/DefaultLayout'
import AuthenticatedLayout from './components/layouts/AuthenticatedLayout'

import './registerServiceWorker'

import 'remixicon/fonts/remixicon.css'
import './assets/scss/main.scss'
import 'vue-select/dist/vue-select.css'

Vue.config.productionTip = false

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('span', '')
  },
  appendToBody: true
})

Vue.component('defaultlayout', DefaultLayout)
Vue.component('authenticatedLayout', AuthenticatedLayout)
Vue.component('v-select', vSelect)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
