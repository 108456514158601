<template>
  <div class="find-business-form">
    <div class="container">
      <h3>Find Business</h3>

      <form class="find-business-form__inner">
        <div class="input-group">
          <v-select
            :options="[
              { label: 'All categories', code: 'all' },
              { label: 'Category one', code: 'one' },
              { label: 'Category two', code: 'twi' }
            ]"
            placeholder="Category"
          />
        </div>

        <div class="input-group">
          <input
            class="form-control"
            type="text"
            placeholder="I'm looking for..."
          />
        </div>

        <div class="input-group">
          <v-select
            :options="[
              { label: 'Canada', code: 'ca' },
              { label: 'USA', code: 'usa' },
              { label: 'Sri Lanka', code: 'lka' }
            ]"
            placeholder="Location"
            class="location"
          />
        </div>

        <div class="input-group">
          <v-select
            :options="[
              { label: '+0Km', code: '0', selected: true },
              { label: '+20Km', code: '20' },
              { label: '+50Km', code: '50' },
              { label: '+100Km', code: '100' }
            ]"
            placeholder="Distance"
          />
        </div>

        <button class="btn btn--primary">Find</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FindBusinessForm',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './FindBusinessForm';
</style>
