import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import AboutUsPage from '@/views/About.vue'
import FindBusinessPage from '@/views/FindBusiness.vue'
import ContactPage from '@/views/Contact.vue'
import SavedFavoritesPage from '@/views/SavedFavorites.vue'
import RegisterPage from '@/views/Register.vue'
import LoginPage from '@/views/Login.vue'
import BusinessRegisterPage from '@/views/BusinessRegister.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: {
      name: 'home'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { layout: 'defaultlayout' }
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUsPage,
    meta: { layout: 'defaultlayout' }
  },
  {
    path: '/find-business',
    name: 'find-business',
    component: FindBusinessPage,
    meta: { layout: 'defaultlayout' }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
    meta: { layout: 'defaultlayout' }
  },
  {
    path: '/saved',
    name: 'saved',
    component: SavedFavoritesPage,
    meta: { layout: 'authenticatedLayout' }
  },
  {
    path: '/signup',
    name: 'signup',
    component: RegisterPage,
    meta: { layout: 'defaultlayout' }
  },
  {
    path: '/signin',
    name: 'signin',
    component: LoginPage,
    meta: { layout: 'defaultlayout' }
  },
  {
    path: '/register-business',
    name: 'register-business',
    component: BusinessRegisterPage,
    meta: { layout: 'defaultlayout' }
  }
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes
})

export default router
