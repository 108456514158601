<template>
  <section class="page page--container">
    <PageContent>
      <SignInBlock />
    </PageContent>
  </section>
</template>

<script>
import { PageContent } from '@/components/molecules/PageContent'
import { SignInBlock } from '@/components/molecules/SignInBlock'

export default {
  name: 'LoginPage',
  data() {},
  components: {
    PageContent,
    SignInBlock
  }
}
</script>

<style scoped lang="scss"></style>
