<template>
  <div class="sign-in-block">
    <div class="authentication-layout">
      <!-- Left -->
      <div class="authentication-layout__left">
        <h2 class="authentication-layout__heading">Sign In</h2>

        <!-- Form -->
        <div class="form">
          <!-- Row -->
          <div class="row">
            <div class="col">
              <input
                type="text"
                placeholder="Username"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row">
            <div class="col">
              <input
                type="password"
                placeholder="Password"
                class="form-control--style-two"
              />
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row" style="padding-top: 20px;">
            <div class="col">
              <button class="btn btn--primary">Sign In</button>
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row" style="padding-top: 20px;">
            <div class="col">
              <a href="#">Forgot your password?</a>
            </div>
          </div>
          <!-- ./Row -->

          <!-- Row -->
          <div class="row">
            <div class="col">
              <a href="#" class="primary">Not a member yet?</a>
            </div>
          </div>
          <!-- ./Row -->
        </div>
        <!-- ./Form -->
      </div>
      <!-- ./Left -->

      <!-- Right -->
      <div
        class="authentication-layout__right"
        style="background-image: url('/images/auth-bg.png')"
      ></div>
      <!-- ./Right -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignInBlock',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './SignInBlock';
</style>
