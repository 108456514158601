<template>
  <div class="avatar">
    <label v-if="label" class="avatar__label">{{ label }}</label>
    <span class="avatar__image">
      <img :src="image" :alt="label" />
    </span>
    <i class="avatar__arrow ri-arrow-down-s-line" />
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  data() {
    return {}
  },
  components: {},
  props: ['image', 'label']
}
</script>

<style scoped lang="scss">
@import './Avatar';
</style>
