<template>
  <div class="search__wrapper">
    <div class="search-input">
      <input
        type="text"
        name="search"
        id="search"
        placeholder="Search Baby Atlas"
        class="search-input__field"
        @focus="toggle"
        v-click-outside="hide"
      />
      <button type="submit" class="search-input__btn">
        <i class="ri-search-2-line" />
      </button>
    </div>

    <div v-show="showSearchDropdown" class="search__dropdown">
      <h4 class="search__dropdown__heading">Trending Search</h4>

      <div class="search__dropdown__pills">
        <a href="" class="pill">"venue"</a>
        <a href="" class="pill">"wedding"</a>
        <a href="" class="pill">"souvenir"</a>
        <a href="" class="pill">"photo"</a>
        <a href="" class="pill">"invitation"</a>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'SearchInput',
  data() {
    return {
      showSearchDropdown: false
    }
  },
  methods: {
    toggle() {
      this.showSearchDropdown = true
    },

    hide() {
      this.showSearchDropdown = false
    }
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss" scoped>
@import './SearchInput.scss';
</style>
