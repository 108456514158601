<template>
  <div class="business-list">
    <div class="container">
      <!-- Item -->
      <div class="business-list__item transparent">
        <div class="col">
          <label>Business Name</label>
        </div>
        <div class="col">
          <label>Category</label>
        </div>
        <div class="col">
          <label>Customer Rating</label>
        </div>
        <div class="col">
          <label>Location</label>
        </div>
        <div class="col">
          <label>Favorites</label>
        </div>
      </div>
      <!-- // Item -->

      <!-- Item -->
      <div class="business-list__item">
        <div class="col">
          <div class="avatar-card">
            <div class="avatar-card__label">RP</div>
            <div class="avatar-card__name">Recon Pregnancy</div>
          </div>
        </div>
        <div class="col">
          <span>Pediatricians</span>
        </div>
        <div class="col">
          <div class="star-ratings">
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill" />
            <i class="star ri-star-fill" />
          </div>
        </div>
        <div class="col">
          <span>Melbourne, AU</span>
        </div>
        <div class="col">
          <label class="favorite">
            <input type="checkbox" checked />
            <i class="checkmark-unchecked ri-heart-line" />
            <i class="checkmark-checked ri-heart-fill" />
          </label>
        </div>
      </div>
      <!-- // Item -->

      <!-- Item -->
      <div class="business-list__item">
        <div class="col">
          <div class="avatar-card">
            <div class="avatar-card__label">RP</div>
            <div class="avatar-card__name">Recon Pregnancy</div>
          </div>
        </div>
        <div class="col">
          <span>Pediatricians</span>
        </div>
        <div class="col">
          <div class="star-ratings">
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill" />
            <i class="star ri-star-fill" />
          </div>
        </div>
        <div class="col">
          <span>Melbourne, AU</span>
        </div>
        <div class="col">
          <label class="favorite">
            <input type="checkbox" />
            <i class="checkmark-unchecked ri-heart-line" />
            <i class="checkmark-checked ri-heart-fill" />
          </label>
        </div>
      </div>
      <!-- // Item -->

      <!-- Item -->
      <div class="business-list__item">
        <div class="col">
          <div class="avatar-card">
            <div class="avatar-card__label">RP</div>
            <div class="avatar-card__name">Recon Pregnancy</div>
          </div>
        </div>
        <div class="col">
          <span>Pediatricians</span>
        </div>
        <div class="col">
          <div class="star-ratings">
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill active" />
            <i class="star ri-star-fill" />
            <i class="star ri-star-fill" />
          </div>
        </div>
        <div class="col">
          <span>Melbourne, AU</span>
        </div>
        <div class="col">
          <label class="favorite">
            <input type="checkbox" />
            <i class="checkmark-unchecked ri-heart-line" />
            <i class="checkmark-checked ri-heart-fill" />
          </label>
        </div>
      </div>
      <!-- // Item -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BusinessList',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './BusinessList';
</style>
