<template>
  <section class="page page--container">
    <PageContent>
      <h2 style="margin-bottom: 20px">Contact Us</h2>
      <p>
        Questions? Drop us a line by filling out the contact form below.
      </p>

      <ContactForm />
    </PageContent>
  </section>
</template>

<script>
import { PageContent } from '@/components/molecules/PageContent'
import { ContactForm } from '@/components/molecules/ContactForm'

export default {
  name: 'ContactPage',
  data() {},
  components: {
    PageContent,
    ContactForm
  }
}
</script>

<style scoped lang="scss"></style>
