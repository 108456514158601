<template>
  <div class="navigation">
    <a href="/signin" class="btn">Sign In</a>
    <a href="/signup" class="btn btn--primary">Sign Up</a>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './Navigation';
</style>
