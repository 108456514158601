<template>
  <div id="app">
    <component :is="addLayout">
      <router-view
        @authenticated="setAuthenticated"
        authenticated="false"
      ></router-view>
    </component>
  </div>
</template>
<script>
const defaultLayout = 'defaultlayout'

export default {
  name: 'App',
  data() {
    return {
      authenticated: false
    }
  },
  mounted() {},
  methods: {
    setAuthenticated(status) {
      this.authenticated = status
    }
  },
  computed: {
    addLayout() {
      return this.$route.meta.layout || defaultLayout
    }
  }
}
</script>

<style scoped lang="scss"></style>
