<template>
  <div class="hero">
    <img src="/images/hero-banner.png" />
    <div class="hero__content">
      <h2>Step into The Baby Atlas</h2>
      <p>A one stop marketplace for all your baby needs.</p>
      <a href="/" class="btn btn--default">Learn More</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './Hero';
</style>
