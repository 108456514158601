<template>
  <section class="page page--container">
    <PageContent>
      <SignUpBlock />
    </PageContent>
  </section>
</template>

<script>
import { PageContent } from '@/components/molecules/PageContent'
import { SignUpBlock } from '@/components/molecules/SignUpBlock'

export default {
  name: 'RegisterPage',
  data() {},
  components: {
    PageContent,
    SignUpBlock
  }
}
</script>

<style scoped lang="scss"></style>
