<template>
  <div class="info-cards-block">
    <div class="container">
      <h3 class="section-heading">
        IVF
      </h3>

      <p class="section-description">
        Find the best fertility and IVF experts who will provide compassionate
        care. Start your family sooner.
      </p>

      <div class="info-cards-block__list">
        <div class="info-card">
          <h2>Melbourne IVF</h2>
          <p>
            Experienaced fertility specialists focused on <br />delivering
            personalised care and results. The <br />Pionners of IVF in
            Australia.
          </p>

          <div class="info-card__cta">
            <a href="/" class="btn btn--primary">Learn More</a>
          </div>
        </div>

        <div class="info-card">
          <h2>Monash IVF</h2>
          <p>
            Starting a family isn’t always easy, it an take real <br />bravery.
            That’s why our team of fertility specialists at <br />Monash IVF are
            here to support you on your journey.
          </p>

          <div class="info-card__cta">
            <a href="/" class="btn btn--primary">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCardsBlock',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './InfoCardsBlock';
</style>
