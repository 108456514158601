<template>
  <section class="page page--container">
    <PageContent>
      <h2>My Saved Favorites</h2>

      <FavoritesList />
    </PageContent>
  </section>
</template>

<script>
import { PageContent } from '@/components/molecules/PageContent'
import { FavoritesList } from '@/components/molecules/FavoritesList'

export default {
  name: 'SavedFavoritesPage',
  data() {},
  components: {
    PageContent,
    FavoritesList
  }
}
</script>

<style scoped lang="scss"></style>
