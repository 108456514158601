<template>
  <div class="page-header">
    <div class="page-header__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './PageHeader';
</style>
