<template>
  <div class="slider-block-three">
    <div class="container">
      <h4 class="section-sub-heading">
        Hospitals
      </h4>

      <p class="section-description">
        The model of care you receive during your pregnancy will depend on your
        health, your risk of complications, where you live, your preferences and
        your availability.
      </p>

      <div class="slider-block-three__slider">
        <template>
          <VueSlickCarousel @init="onInitCarousel" v-bind="settings">
            <img class="slider-logo" src="/images/logo/0.png" />
            <img class="slider-logo" src="/images/logo/1.png" />
            <img class="slider-logo" src="/images/logo/2.png" />
            <img class="slider-logo" src="/images/logo/3.png" />
            <img class="slider-logo" src="/images/logo/4.png" />
            <img class="slider-logo" src="/images/logo/5.png" />
            <img class="slider-logo" src="/images/logo/0.png" />
            <img class="slider-logo" src="/images/logo/1.png" />
            <img class="slider-logo" src="/images/logo/2.png" />
            <img class="slider-logo" src="/images/logo/3.png" />
            <img class="slider-logo" src="/images/logo/4.png" />
            <img class="slider-logo" src="/images/logo/5.png" />

            <template #prevArrow="arrowOption">
              <div class="arrow">
                <img src="/images/arrow-left.png" />
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>

            <template #nextArrow="arrowOption">
              <div class="arrow">
                <img src="/images/arrow-right.png" />
                {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
              </div>
            </template>

            <template #customPaging>
              <div class="slide-dot" />
            </template>
          </VueSlickCarousel>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'SliderBlockThree',
  data() {
    return {
      settings: {
        autoplay: true,
        adaptiveHeight: true,
        arrows: true,
        slidesToShow: 6,
        draggable: false,
        responsive: [
          {
            breakpoint: 1171,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          }
        ]
      }
    }
  },
  components: { VueSlickCarousel }
}
</script>

<style lang="scss">
@import './SliderBlockThree';
</style>
