<template>
  <div class="page-content">
    <div class="container">
      <div class="page-content__content">
        <div class="page-content__wrapper">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContent',
  data() {
    return {}
  },
  components: {}
}
</script>

<style scoped lang="scss">
@import './PageContent';
</style>
